<template>
	<div class="nav_bar_wrapper">
		<div class="nav_bar">
			<div class="nav_bar_content">
				<div class="left_items flex_grow">
					<Icon
						id="menu_icon"
						name="md-menu"
						@click="toggleMenuMobile"
					/>
					<div class="left_content">
						<v-menu
							offset="17"
							:close-on-content-click="true"
						>
							<template #activator="{ props }">
								<Button
									v-bind="props"
									variant="flat"
									rounded="xl"
									class="-ml-[13px]"
								>
									<Icon
										name="md-menu"
										class="mr-[8px]"
									/>
									<span>Products</span>
								</Button>
							</template>
							<v-card
								class="mx-auto main_menu_card"
								width="600"
								@mouseleave="showSubcategory(null)"
							>
								<v-list v-model:opened="open">
									<v-list-item
										class="font-bold"
										@click="navigateTo('/' + organizationProfile?.slug + '/products')"
										@mouseover="showSubcategory(null)"
										>All products
									</v-list-item>
									<v-list-item
										v-for="item in data?.categories"
										:key="item.id"
										@click="navigateTo('/' + organizationProfile?.slug + '/products?category=' + item.id)"
										@mouseover="showSubcategory(item)"
									>
										{{ $t(item.text) }}
									</v-list-item>
								</v-list>
								<div class="subcategory">
									<v-list-item
										v-for="item in activeSubcategories"
										:key="item.id"
										@click="navigateTo('/' + organizationProfile?.slug + '/products?category=' + item.id)"
									>
										{{ item.text }}
									</v-list-item>
								</div>
							</v-card>
						</v-menu>
					</div>
					<!-- <div class="left_content">
						<v-menu offset="17">
							<template #activator="{ props }">
								<Button
									v-bind="props"
									variant="flat"
									rounded="xl"
								>
									<Icon
										name="md-grid_view"
										class="mr-[8px]"
									/>
									<span id="desktop_text">Collections</span>
								</Button>
							</template>
							<v-list>
								<v-list-item
									class="text-neutral-900 font-bold"
									@click="navigateTo('/' + organizationProfile?.slug + '/collections')"
									>All collections</v-list-item
								>
								<v-list-item
									v-for="collection in data?.collections"
									:key="collection.id"
									@click="navigateTo('/' + organizationProfile?.slug + '/collections/' + collection.id)"
								>
									{{ collection.name }}
								</v-list-item>
							</v-list>
						</v-menu>
					</div> -->
					<!-- <div class="text-neutral-500 left_content">
						<v-menu offset="17">
							<template #activator="{ props }">
								<Button
									v-bind="props"
									variant="flat"
									rounded="xl"
								>
									<Icon
										name="md-search"
										class="mr-[8px]"
									/>
									<span id="desktop_text">Search</span>
								</Button>
							</template>
							<v-card min-width="730">
								<v-list>
									<v-list-item>
										<SearchInput
											v-model="searchInput"
											placeholder="Search by name..."
											clearable
											class="search_input mr-[8px] mobile:mr-0"
											@update:model-value="onSearchUpdate"
										/>
									</v-list-item>
								</v-list>

								<div class="px-[24px] mb-[12px] text-[16px] font-bold text-neutral-900">
									Search results is disabled for now.<span class="text-[12px] font-normal text-neutral-700"
										>({{ searchResults.length }})</span
									>
								</div>
								<v-divider></v-divider>
								<v-list id="search_result_list">
									<v-list-item
										v-for="(item, index) in searchResults"
										:key="index"
									>
										<div class="result_item">
											<div class="image_name">
												<div
													class="image"
													:style="{ 'background-image': `url(${item.imageUrl})` }"
												></div>
												<div class="name">
													<span class="font-bold">DTY </span>
													{{ item.name }}
												</div>
											</div>
											<div class="price">
												<span>${{ item.price }} </span><span class="text-[12px]">USD</span>
											</div>
										</div>
										<v-divider></v-divider>
									</v-list-item>
								</v-list>

								<div class="px-[24px] h-[48px] leading-[48px] text-blue-200 mt-[-10px]">View all results</div>
							</v-card>
						</v-menu>
					</div> -->
				</div>
				<div class="middle_items flex_grow">
					<NuxtLink :to="'/' + organizationProfile?.slug">
						<img
							v-if="logoUrl !== ''"
							:src="logoUrl"
							class="max-h-[70px]"
						/>
						<div v-else>
							<span class="org_title">{{ organizationProfile?.public_name }}</span>
						</div>
					</NuxtLink>
				</div>
				<div class="right_items flex_grow">
					<div class="right_content">
						<v-menu offset="17">
							<template #activator="{ props }">
								<Button
									v-bind="props"
									variant="flat"
									rounded="xl"
									class="-ml-[13px]"
									@click="user ? undefined : handleRedirect()"
								>
									<Icon
										name="md-account_circle"
										class="mr-[8px]"
									/>
									<span id="desktop_text">My account</span>
								</Button>
							</template>

							<v-card
								v-if="userName"
								min-width="300"
							>
								<v-list>
									<v-list-item
										id="personal_info"
										:title="`${userName}`"
										:subtitle="`${userOrganizationProfile?.public_name}` ?? ''"
									>
										<template #prepend>
											<v-avatar
												class="ml-[8px] mr-[4px]"
												color="black"
												size="40"
											>
												{{ userInitial }}
											</v-avatar>
										</template>
									</v-list-item>
								</v-list>

								<v-divider></v-divider>

								<v-list>
									<v-list-item @click="showPopup = true">Orders</v-list-item>
									<v-list-item @click="showPopup = true">Quote requests</v-list-item>
									<v-list-item @click="showPopup = true">Favorites</v-list-item>
								</v-list>

								<v-divider></v-divider>

								<v-list>
									<NuxtLink to="https://system.tengiva.com/dashboard">
										<v-list-item class="control_items">
											<span>Go to my admin</span>
											<Icon name="md-open_in_new" />
										</v-list-item>
									</NuxtLink>

									<v-list-item
										class="control_items"
										@click="logout"
									>
										<span>Sign out</span>
										<Icon name="md-logout" />
									</v-list-item>
								</v-list>
							</v-card>
						</v-menu>
					</div>
					<div
						class="cursor-pointer"
						@click="goToMyCart"
					>
						<Icon
							name="md-local_mall"
							class="mr-[3px]"
						/>
						<Badge
							:visible="true"
							:content="numOfTextileItems ?? 0"
							:color="numOfTextileItems > 0 ? 'red' : 'black'"
							class="-mt-[20px] mr-[7px]"
						/>
						<span class="mobile:hidden">My cart</span>
					</div>
				</div>
			</div>

			<!-- mobile search -->
			<!-- <div class="mobile_search">
				<v-menu offset="17">
					<template #activator="{ props }">
						<SearchInput
							v-model="searchInput"
							v-bind="props"
							placeholder="Search by name..."
							clearable
							class="search_input mr-[8px] mobile:mr-0"
							@update:model-value="onSearchUpdate"
						/>
					</template>
					<v-card
						min-width="450"
						class="mt-[-10px]"
					>
						<div class="mt-4 px-[24px] mb-[12px] text-[16px] font-bold text-neutral-900">
							Search results is disabled for now.<span class="text-[12px] font-normal text-neutral-700"
								>({{ searchResults.length }})</span
							>
						</div>
						<v-divider></v-divider>
						<v-list id="search_result_list">
							<v-list-item
								v-for="(item, index) in searchResults"
								:key="index"
							>
								<div class="result_item">
									<div class="image_name">
										<div
											class="image"
											:style="{ 'background-image': `url(${item.imageUrl})` }"
										></div>
										<div class="name">
											<span class="font-bold">DTY </span>
											{{ item.name }}
										</div>
									</div>
									<div class="price">
										<span>${{ item.price }} </span><span class="text-[12px]">USD</span>
									</div>
								</div>
								<v-divider></v-divider>
							</v-list-item>
						</v-list>

						<div class="px-[24px] h-[48px] leading-[48px] text-blue-200 mt-[-10px]">View all results</div>
					</v-card>
				</v-menu>
			</div> -->
		</div>
		<NotAvailableForDemo
			v-model="showPopup"
			closable
			width="700"
			:has-button="false"
		/>
	</div>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import $bus from "../../bus";
import Icon from "../Icon/index.vue";
import Badge from "../Badge.vue";
// import SearchInput from "../Input/SearchInput.vue";
import Button from "../Button.vue";
import NotAvailableForDemo from "@/components/NotAvailableForDemo.vue";
import { useUserStore } from "@/stores/modules/user";
import { useOrgStore } from "@/stores/modules/organization";
import { useCartStore } from "@/stores/modules/cart";
import type { Category } from "@/types/products";
// const { listCategoriesForShowroom, listCollections } = usePM();

const cartStore = useCartStore();
const { numOfTextileItems } = storeToRefs(cartStore);
const userStore = useUserStore();
const { user, userOrganizationProfile } = storeToRefs(userStore);
const $route = useRoute();
const $router = useRouter();
const orgStore = useOrgStore();

const { organizationProfile } = storeToRefs(orgStore);
if (!organizationProfile.value) {
	throw new Error("Organization is not defined");
}

const userInitial = computed(() => {
	if (!user.value) return "";
	return `${user.value.first_name.charAt(0)}${user.value.last_name.charAt(0)}`;
});

const userName = computed(() => {
	if (!user.value) return "";
	return `${user.value.first_name} ${user.value.last_name}`;
});

const handleRedirect = () => {
	$router.push({ path: "/signin-signup?tab=signin", query: { redirect: $route.path } });
};

const showPopup = ref(false);

// Collections
const activeSubcategories = ref<Category[]>();

// const { data } = await useAsyncData("collections-and-categories", async () => {
// 	if (!organizationProfile.value) {
// 		throw new Error("Organization is not defined");
// 	}
// 	const [categories, collections] = await Promise.all([
// 		listCategoriesForShowroom(organizationProfile.value.organization_id),
// 		listCollections(organizationProfile.value.organization_id),
// 	]);

// 	return {
// 		// categories,
// 		// collections,
// 	};
// });

const showSubcategory = (item: Category | null) => {
	if (item) {
		activeSubcategories.value = item.subcategories;
	} else {
		activeSubcategories.value = [];
	}
};

// Search
// const searchInput = ref("");
// function onSearchUpdate() {}

// const searchResults = ref([]);

const open = ["Products"];

const { clearCartData } = useCartStore();
const logout = () => {
	userStore.userLogout();
	// clear cart info
	clearCartData();
	$router.push({ path: "/signin-signup?tab=signin", query: { redirect: $route.path } });
};

const goToMyCart = () => {
	if (organizationProfile.value) {
		$router.push("/" + organizationProfile.value.slug + "/my-cart");
	}
};

const toggleMenuMobile = () => {
	$bus.emit("toggleMenu");
};

const logoUrl = ref("");
const { getPlatformSettingsPlatform } = useECMApi();
const { mode } = useRuntimeConfig().public;

await useAsyncData("platform", async () => {
	if (!organizationProfile.value) {
		throw new Error("Organization is not defined");
	}
	const { response } = await getPlatformSettingsPlatform(organizationProfile.value?.organization_id as string);
	return response?.data;
}).then(response => {
	if (mode === "production") {
		logoUrl.value =
			"https://tengiva-ecm-wpc-prod.imgix.net/" +
			(response.data.value as any).platform_settings?.[0]?.id +
			"/" +
			(response.data.value as any).platform_settings?.[0]?.platform_branding?.logo;
	} else {
		logoUrl.value =
			"https://tengiva-ecm-wpc-dev.imgix.net/" +
			(response.data.value as any).platform_settings?.[0]?.id +
			"/" +
			(response.data.value as any).platform_settings?.[0]?.platform_branding?.logo;
	}
});

/* const categories = [
	{
		id: "all_products",
		label: "components.navigations.label.all_products",
		url: "/products",
	{
		id: "category_name1",
		label: "components.navigations.label.category_name1",
		subcategory: [
			{
				name: "subcategory 11",
				path: "/category_name1/subcategory11",
			},
			{
				name: "subcategory 12",
				path: "/category_name1/subcategory12",
			},
			{
				name: "subcategory 13",
				path: "/category_name1/subcategory13",
			},
		],
	},
]; */
</script>
<style lang="scss" scoped>
.nav_bar_wrapper {
	@apply h-[72px] w-full fixed top-[30px] mobile:top-0 mobile:h-[120px];
	.nav_bar {
		@apply h-[72px] px-[40px] flex text-neutral-900 max-w-[1440px] mx-auto;
		@apply mobile:p-[15px] mobile:h-[120px] mobile:flex-col;
		.nav_bar_content {
			@apply flex w-full mobile:h-[50px];

			.flex_grow {
				@apply flex flex-1 items-center;
			}

			.left_items,
			.right_items {
				@apply gap-x-[40px] mobile:gap-x-[5px] tablet:gap-x-[5px];
				div {
					@apply flex items-center;
				}
			}

			.left_items {
				@apply justify-start gap-x-[40px] mobile:gap-x-[5px] tablet:gap-x-[5px];

				#menu_icon {
					@apply hidden mobile:inline-block;
				}

				.left_content {
					@apply mobile:hidden;
				}
			}

			.right_items {
				@apply justify-end;

				.right_content {
					@apply mobile:hidden;
				}
			}

			.middle_items {
				@apply justify-center;
			}
		}

		.mobile_search {
			@apply hidden mobile:block;
		}

		#desktop_text {
			@apply tablet:hidden;
		}
	}
}

.v-btn--variant-flat {
	&:focus {
		@apply shadow-none;
		:deep(.v-btn__underlay) {
			@apply border-none rounded-[4px];
		}
	}
}

.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
	@apply px-[24px];
}
.v-list-item {
	:deep(.v-list-item__content) {
		@apply text-neutral-900;
	}
}
.control_items {
	:deep(.v-list-item__content) {
		@apply flex items-center justify-between;
	}
}

#personal_info {
	:deep(.v-list-item-title) {
		@apply text-neutral-900 font-bold;
	}
	:deep(.v-list-item-subtitle) {
		@apply text-neutral-700;
	}
}

hr {
	@apply border border-neutral-100 opacity-100;
}

.result_item {
	@apply flex justify-between items-center mb-[10px] px-[24px];

	.image_name {
		@apply flex items-center gap-[12px];

		.image {
			@apply w-[60px] h-[40px] rounded;
		}

		.name {
			@apply text-neutral-900;
		}
	}
	.price {
		@apply text-neutral-900 text-[14px];
	}
}

#search_result_list {
	.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
		@apply px-0;
	}
}

.main_menu_card {
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	@apply flex;

	div {
		@apply w-[50%];

		.v-list-item {
			@apply w-full;

			&:hover {
				@apply bg-neutral-50;
			}
		}
	}

	.subcategory {
		@apply bg-neutral-50 py-[8px];
		.v-list-item {
			@apply px-[20px] h-[48px];
		}
	}
}
.menu_card {
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	@apply fixed top-[-104px] pt-[20px];
}

.menu_category {
	:deep(.v-list-item__content) {
		@apply flex items-center;
	}
}

.org_title {
	font-size: xx-large;
	font-family: "Lato";
}
</style>
