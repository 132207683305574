<template>
	<div class="item">
		<h1 class="title">
			<v-list>
				<v-list-item
					id="personal_info"
					:title="`${userName}`"
					:subtitle="`${userOrganizationProfile?.public_name}` ?? ''"
				>
					<template #prepend>
						<v-avatar
							class="mr-[4px]"
							color="black"
							size="40"
						>
							{{ userInitial }}
						</v-avatar>
					</template>
				</v-list-item>
			</v-list>
		</h1>

		<div class="links">
			<MenuLink
				v-for="item in accountItems"
				:key="item.id"
				@click="showPopup = true"
			>
				{{ $t(item.label) }}
			</MenuLink>
		</div>
		<NotAvailableForDemo
			v-model="showPopup"
			closable
			width="700"
			:has-button="false"
		/>
	</div>
</template>
<script setup lang="ts">
import MenuLink from "@/components/Navigation/Submenu/MenuLink.vue";
import { useUserStore } from "@/stores/modules/user";
import NotAvailableForDemo from "@/components/NotAvailableForDemo.vue";

const userStore = useUserStore();
const { user, userOrganizationProfile } = storeToRefs(userStore);
const showPopup = ref(false);

const userInitial = computed(() => {
	if (!user.value) return "";
	return `${user.value.first_name.charAt(0)}${user.value.last_name.charAt(0)}`;
});

const userName = computed(() => {
	if (!user.value) return "";
	return `${user.value.first_name} ${user.value.last_name}`;
});

const accountItems = [
	{
		id: "orders",
		label: "components.navigations.label.orders",
	},
	{
		id: "quote_requests",
		label: "components.navigations.label.quote_requests",
	},
	{
		id: "favorites",
		label: "components.navigations.label.favorites",
	},
];
</script>
<style lang="scss" scoped>
.title {
	@apply px-[20px];

	:deep(.v-list-item) {
		@apply px-0;
	}
}
.links {
	@apply border-t-[1px] border-neutral-100 pt-[25px] px-[20px];
}
</style>
